.screenReaderOnly {
  border: 0; 
  clip: rect(0 0 0 0); 
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px; 
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.mentors {
  position: relative;
  margin: auto;
  margin-top: 50px;
  right: 3%;
  
  display: flex;
  flex-direction: column;
  
  width: 70%;
  max-width: 1600px;
  color: white;

  @media screen and (min-width: 1600px) {
    width: 1100px;
  }

  @media screen and (max-width: 1200px) {
    right: 0;
    width: 85%;
  }

  .title {
    font-size: 2.2em;
    font-weight: 600;
    margin-bottom: 50px;
    text-transform: uppercase;

    @media screen and (max-width: 1200px) {
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.6em;
      margin-bottom: 40px;
    }
  }

  .mentor {
    --image-size: 230px;
    display: flex;
    width: 100%;
    margin: 25px auto;
    align-items: center;
    
    @media screen and (max-width: 1200px) {
      --image-size: 200px;
    }

    @media screen and (max-width: 767px) {
      --image-size: 150px;
      display: block;
      font-size: .95em;
    }

    @media screen and (max-width: 550px) {
      font-size: .85em;
    }

    @media screen and (min-width: 1800px) {
      font-size: 1.05em;
    }

    .headshot {
      min-width: var(--image-size);
      width: var(--image-size);
      height: var(--image-size);
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 200px;
      margin-right: 50px;
      display: block;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .name {
      margin: 0;
      font-size: 1.65em;
      font-weight: 400;
      @media screen and (max-width: 1200px) {
        font-size: 1.1em;
        font-weight: 600;
      }
    }

    .project {
      margin: 0;
      font-weight: 600;
      font-size: 1.1em;
      color: #ADD8CD;
      margin-top: 5px;
      display: block;
      text-decoration: none;
      overflow-wrap: break-word;
      &:hover {
        text-decoration: underline;
      }
    }

    .bio {
      line-height: 1.5em;
      font-weight: 300;
      white-space: pre-line;
      font-size: .95em;
      @media screen and (max-width: 767px) {
        font-weight: 400;
      }

      a {
        color: #ADD8CD;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
