.timeline {
  color: white;
  margin-top: 125px;
  position: relative;

  .title {
    font-weight: 600;
    font-size: 2.1em;
    margin-bottom: 50px;
    &.mobile {
      display: none;
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      display: none;
      &.mobile {
        display: block;
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-around;
    width: 75%;
    margin: auto;
    max-width: 1200px;
    
    @media screen and (max-width: 1200px) {
      width: 85%;
    }
    
    @media screen and (max-width: 767px) {
      width: 90%;
      justify-content: space-between;
    }
    
    .decorations {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      margin-top: 50px;
      --scale: .75;

      @media screen and (max-width: 767px) {
        --scale: .5;
        margin-top: 0;
        margin-bottom: 50px;
        width: 15%;
      }

      @media (max-width: 550px) {
        width: 20%;
      }
      
      @media (max-width: 450px) {
        width: 25%;
      }

      @media (max-width: 400px) {
        --scale: .45;
      }

      img {
        position: relative;
        margin: 10px auto;
        z-index: 1;
      }
      
      .fireStick {
        width: calc(var(--scale) * 140px);
        right: 35px;
        @media screen and (max-width: 767px) {
          right: 0;
        }
      }
      
      .rocks {
        width: calc(var(--scale) * 120px);
      }
      
      .sticks {
        width: calc(var(--scale) * 125px);
        right: 20px;
        @media screen and (max-width: 767px) {
          right: 0;
        }
      }
      
      .campfire {
        width: calc(var(--scale) * 150px);
      }
      
      .smoke {
        position: absolute;
        height: 68%;
        left: 50%;
        top: 50%;
        transform: translate(-60%, -55%);
        z-index: 0;

        @media screen and (max-width: 900px) {
          width: 110%;
          height: 74%;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }
    }
    
    .content {
      width: 70%;
      display: flex;
      flex-direction: column;
      white-space: pre-line;
      line-height: 1.5em;
      
      @media (max-width: 767px) {
        width: 80%;
        font-size: .9em;
      }

      @media (max-width: 550px) {
        width: 75%;
      }

      @media (max-width: 450px) {
        width: 70%;
      }

      .text {
        margin: 0;
        padding-bottom: 50px;
        margin-top: 15px;
        @media (max-width: 1300px) {
          margin-top: 0;
        }
      }
    }
  }
}
