@mixin large-screen {
  @media screen and (min-width: 1800px), screen and (min-width: 1500px) and (min-height: 1100px) {
    @content
  }
}

.hero {
  position: relative;

  width: 100%;
  height: 100vh;

  overflow: hidden;
  user-select: none;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    min-height: 650px;
  }

  @media screen and (min-width: 768px) and (max-width: 900px) {
    min-height: 800px;
  }

  img {
    -webkit-user-drag: none;
  }

  .logo {
    position: absolute;
    top: 30px;
    left: 30px;

    width: 100px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .logoMobile {
    display: none;
    height: 13%;
    position: absolute;
    top: 7%;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 767px) {
      display: initial;
    }
  }

  .wave1 {
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 75%;
  }

  .wave2 {
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 60%;
  }

  .topStars {
    height: 35%;

    background-size: auto 150%;
    background-position: right;
  }

  .centerStars {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-58%);
    
    height: 91%;

    @media screen and (max-width: 1200px) {
      transform: translateX(-64%);
    }

    @media screen and (max-width: 1023px) {
      transform: translateX(-70%);
    }

    @media screen and (max-width: 767px) {
      transform: translateX(-35%);
      height: 100%;
      bottom: -1%;
    }
  }

  .moon {
    position: absolute;
    right: 6vw;
    top: 6%;

    height: 30%;

    @media screen and (max-width: 767px) {
      height: 125px;
      top: 3%;
      right: 4%;
    }
  }

  .leftTrees {
    position: absolute;
    left: 0;
    bottom: 5%;

    height: 88%;

    @media screen and (max-width: 1000px) {
      left: -60px;
    }

    @media screen and (max-width: 900px) {
      left: -100px;
    }

    @media screen and (max-width: 767px) {
      height: 78%;
      left: -60px;
      bottom: 2%;
    }
  }

  .rightTrees {
    position: absolute;
    right: 0;
    bottom: 12%;

    height: 55%;

    @media screen and (max-width: 1200px) {
      right: -40px;
    }

    @media screen and (max-width: 900px) {
      right: -60px;
    }

    @media screen and (max-width: 767px) {
      height: 42%;
      right: -25px;
      bottom: 5%;
    }

    @include large-screen {
      display: none;
    }

    &.full {
      display: none;
      right: -150px;

      @include large-screen {
        display: initial;
      }
    }
  }

  .bottomLandscape {
    position: absolute;
    bottom: -1.25vw;
    left: 0;

    width: 100%;
    height: 19%;

    @media screen and (max-width: 1200px) {
      height: 18%;
    }

    @media screen and (max-width: 767px) {
      height: 11%;
    }
  }

  .header {
    user-select: text;
    color: white;

    position: absolute;
    left: 20%;
    top: 28%;

    @media screen and (max-width: 1200px) {
      top: 25%;
      font-size: .9em
    }

    @media screen and (max-width: 1000px) {
      left: 15%;
    }

    @media screen and (max-width: 900px) {
      left: 10%;
    }

    @media screen and (min-height: 900px) and (min-width: 1200px) {
      left: 23%;
    }

    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: .75em;
      
      width: 100%;
      left: 0;
      top: 28%;
    }

    @include large-screen {
      font-size: 1.1em;
    }

    h1 {
      font-weight: 600;
      font-size: 3em;
      text-transform: uppercase;

      margin: 0;
    }

    h3 {
      font-weight: 600;
    }

    h4 {
      font-weight: 400;
      text-transform: uppercase;
      margin: 0;
      font-size: 1.35em;
      margin-left: 4px;
      margin-bottom: 8px;
      font-weight: 300;
    }

    .applyButton {
      display: inline-block;
      text-decoration: none;
      background-color: #EE6C72;
      color: white;

      text-transform: uppercase;
      font-weight: 600;
      font-size: 1em;

      padding: 7px 14px;
      border-radius: 100px;
    }
  }

  .description {
    user-select: text;
    color: white;

    position: absolute;
    right: 16%;
    bottom: 11%;

    font-weight: 300;
    text-align: center;
    line-height: 25px;
    font-size: .85em;

    width: 375px;

    @media screen and (max-width: 1200px) {
      bottom: 8%;
      font-size: .8em;
    }

    @media screen and (min-height: 900px) and (max-width: 1000px) {
      bottom: 9%;
    }

    @media screen and (min-height: 900px) and (min-width: 1000px) {
      right: 21%;
      bottom: 10%;
    }
    
    @include large-screen {
      right: 25%;
      font-size: .95em;
      width: 450px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
