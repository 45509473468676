.eventInfo {
  position: relative;
  margin-top: -26px;
  padding-top: 1px;
  min-height: 57vw;
  font-size: .95em;

  @media screen and (min-width: 1800px) {
    min-height: 0;
    height: 1000px;
  }

  @media screen and (max-width: 767px) {
    margin-top: -16px;
  }


  .campfire {
    -webkit-user-drag: none;
    display: none;
    position: absolute;
    top: 0;

    width: 90%;
    max-width: 1500px;

    &.desktop {
      display: initial;
    }
    
    @media screen and (max-width: 1200px) {
      &.tablet {
        display: initial;
      }
      &.desktop, &.mobile {
        display: none;
      }
    }

    @media (max-width: 767px) {
      &.mobile {
        display: initial;
      }
      &.tablet, &.desktop {
        display: none;
      }
    }
  }

  .descriptionContainer {
    display: none;
    min-height: 55vw;
    align-items: center;
    
    @media (max-width: 767px) {
      display: flex;
    }

    .description {
      position: relative;
      z-index: 1;
      
      color: white;
      text-align: center;
      line-height: 1.5em;
      font-size: .95em;

      margin-left: 35%;
      margin-right: 5%;
      margin-top: 50px;
      
      @media (max-width: 550px) {
        font-size: .85em;
      }

      @media (max-width: 450px) {
        font-size: .8em;
      }
    }
  }

  .content {
    position: relative;
    z-index: 1;
    
    padding-bottom: 1px;
    margin-top: 10vw;
    margin-left: 40%;
    margin-right: 10%;
    max-width: 1100px;

    @media screen and (min-width: 1800px) {
      font-size: 1.05em;
    }
    
    @media screen and (max-width: 1200px) {
      margin-left: 32%;
      margin-right: 8%;
      font-size: .95em;
    }

    @media screen and (max-width: 767px) {
      margin-left: 10%;
      margin-right: 10%;
    }
    
    @media screen and (max-width: 550px) {
      font-size: .85em;
    }
    
    h1 {
      color: #46959F;
      font-weight: 600;
      font-size: 2em;
    }
    
    p {
      color: white;
      font-size: 1em;
      line-height: 1.5em;
      margin-bottom: 35px;

      &.bold {
        font-weight: 500;
      }
    }
  }
}
