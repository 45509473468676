.faq {
  position: relative;
  padding: 1px 0;
  margin-top: 100px;
  overflow: hidden;
  font-size: .98em;

  @media screen and (max-width: 1800px) {
    font-size: .9em;
  }

  @media screen and (max-width: 767px) {
    margin-top: 0;
  }

  .blob {
    position: absolute;
    height: 100%;
    width: 70%;
    max-width: 1260px;

    @media screen and (max-width: 1200px) {
      width: 100%;
      left: -25px;
    }

    @media screen and (max-width: 767px) {
      width: auto;
      height: calc(100% - 70px);
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    
    @media screen and (max-width: 600px) {
      transform: translateX(-40%);
    }

    @media screen and (max-width: 500px) {
      transform: translateX(-35%);
    }
  }

  .cup {
    z-index: 1;
    
    position: absolute;
    right: 7%;
    bottom: 20%;

    --width: 30vw;
    width: var(--width);
    max-width: 540px;
    height: calc(1.3 * var(--width));
    max-height: calc(1.3 * 540px);

    @media screen and (max-width: 1200px) {
      --width: 23%;
      bottom: 30%;
    }

    @media screen and (max-width: 767px) {
      top: 0;
      right: auto;
      left: 50%;
      transform: translateX(-75%);
      --width: 120px;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    line-height: 1.5em;

    margin: 120px 0 100px min(10%, 180px);
    padding-bottom: 1px;
    width: 50%;
    max-width: 900px;

    @media screen and (max-width: 1200px) {
      margin-bottom: 110px;
      width: 60%;
      font-size: .95em;
    }

    @media screen and (max-width: 767px) {
      font-size: .9em;
      width: auto;

      margin-right: 10%;
      margin-bottom: 75px;
      margin-top: 190px;
    }

    h1 {
      font-size: 2.15em;
      font-weight: 600;
      margin-bottom: 35px;

      @media screen and (max-width: 767px) {
        font-size: 1.6em;
        text-align: center;
        margin-bottom: 30px;
      }
    }

    h4 {
      font-size: 1.15em;
      font-weight: 600;
      color: #3C519C;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        font-size: 1em;
      }
    }

    p {
      margin-top: 3px;
      color: #303030;
    }
  }
}